define("cropster-sharing-frontend/components/lot-details/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    l10n: Ember.inject.service(),
    dialog: Ember.inject.service(),
    router: Ember.inject.service(),
    lot: null,
    lotData: Ember.computed.alias('lot.dataContent'),
    actions: {
      changeStatus(status) {
        let lot = this.lot;
        let importedDate = status === 'IMPORTED' ? (0, _moment.default)() : null;
        Ember.set(lot, 'status', status);
        Ember.set(lot, 'importedDate', importedDate);
        return lot.save();
      }

    },
    removeTask: (0, _emberConcurrency.task)(function* () {
      let lot = this.lot,
          l10n = this.l10n,
          dialog = this.dialog,
          router = this.router;

      try {
        yield dialog.confirmDelete(l10n.t('Are you sure you want to remove the coffee from your list?'), l10n.t('Remove coffee from list'));
      } catch (e) {
        // user rejected dialog
        return;
      }

      yield lot.destroyRecord();
      router.transitionTo('index');
    })
  });

  _exports.default = _default;
});