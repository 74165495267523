define("cropster-sharing-frontend/helpers/pn", ["exports", "ember-l10n/helpers/pn"], function (_exports, _pn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pn.default;
    }
  });
  Object.defineProperty(_exports, "pn", {
    enumerable: true,
    get: function get() {
      return _pn.pn;
    }
  });
});