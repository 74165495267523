define("cropster-sharing-frontend/lot/model", ["exports", "ember-data", "moment", "ember-cropster-common/utils/merge-deep"], function (_exports, _emberData, _moment, _mergeDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Model = _emberData.default.Model,
        attr = _emberData.default.attr;

  var _default = Model.extend({
    name: attr('string'),
    status: attr('string', {
      defaultValue: 'OPEN'
    }),
    note: attr('string'),
    dataContent: attr('json'),
    createdDate: attr('timestamp', {
      defaultValue() {
        return (0, _moment.default)();
      }

    }),
    importedDate: attr('timestamp'),

    // Get the data content for importing
    getImportDataContent() {
      let dataContent = this.dataContent || {};

      if (this.note) {
        return (0, _mergeDeep.default)(dataContent, {
          notes: this.note
        });
      }

      return (0, _mergeDeep.default)(dataContent);
    },

    // multiple variety support
    // split the variety string on commas, trim strings, filter only existing
    prepareDataContent() {
      let dataContent = Object.assign({}, this.getImportDataContent());
      dataContent.varieties = dataContent.variety ? dataContent.variety.split(',') : [];
      dataContent.varieties = dataContent.varieties.map(variety => variety.trim()).filter(variety => variety);
      delete dataContent.variety;
      return dataContent;
    }

  });

  _exports.default = _default;
});