define("cropster-sharing-frontend/create-samples/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IQdEXXqJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Create labels\"],null]],null],false],[0,\"\\n\\n\"],[1,[21,\"import-header-bar\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper wrapper--no-width\"],[9],[0,\"\\n\\n  \"],[7,\"h1\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Create labels\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"max-width-7 margin-bottom-3\"],[9],[0,\"\\n    \"],[5,\"info-message\",[],[[\"@icon\"],[\"tip-icon\"]],{\"statements\":[[0,\"\\n\"],[0,\"      \"],[1,[27,\"t\",[\"If you want to copy-paste from a spreadsheet or manage more samples here with more features simply contact\\n        {{supportEmail}} to get a free trial account.\"],[[\"supportEmail\"],[\"<a href=\\\"sales@cropster.com\\\" target=\\\"_blank\\\" rel=\\\"noopener\\\">sales@cropster.com</a>\"]]],true],[0,\"\\n\"],[0,\"\\n      \"],[1,[27,\"t\",[\"Already a Cropster customer? You can just go to your samples overview and generate the labels from there.\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[5,\"create-samples-form\",[],[[\"@onCreate\"],[[27,\"action\",[[22,0,[]],\"onCreate\"],null]]]],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/create-samples/template.hbs"
    }
  });

  _exports.default = _default;
});