define("cropster-sharing-frontend/components/import-header-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R2YdXJwf",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"import-header-bar/main-nav\",null,[[\"class\"],[\"main-nav\"]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"logo-container\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"logo no-border\"]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-jar\",[\"cropster-interchange\"],[[\"class\"],[\"block icon--large\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"import.help\"],[[\"class\"],[\"button button--secondary button--icon help-link padding-xsmall no-border\"]],{\"statements\":[[0,\"  \"],[1,[27,\"svg-icon\",[\"help\"],null],false],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"How does it work?\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/import-header-bar/template.hbs"
    }
  });

  _exports.default = _default;
});