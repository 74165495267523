define("cropster-sharing-frontend/services/keen", ["exports", "ember-keen/services/keen"], function (_exports, _keen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function calculateRatio(width, height) {
    let w = width / height;
    let h = 1;
    let ratio = w;

    while (w % 1 || h % 1) {
      h++;
      w = h * ratio;
    }

    return [w, h];
  }
  /* istanbul ignore next */


  function getScreenData() {
    let screenCategory = 'unkown';
    let width = window.innerWidth;
    let height = window.innerHeight;
    let biggerDimension = Math.max(width, height);
    let smallerDimenstion = Math.min(width, height);

    if (biggerDimension > 1920) {
      screenCategory = 'xlarge';
    } else if (biggerDimension >= 1300) {
      screenCategory = 'large';
    } else if (biggerDimension >= 800) {
      screenCategory = 'medium';
    } else if (biggerDimension >= 380) {
      screenCategory = 'small';
    } else if (biggerDimension > 0) {
      screenCategory = 'xsmall';
    }

    let ratio = calculateRatio(biggerDimension, smallerDimenstion);
    return {
      screenCategory,
      width,
      height,
      ratio,
      biggerDimension,
      smallerDimenstion
    };
  }

  var _default = _keen.default.extend({
    userSettings: Ember.inject.service(),
    canWrite: true,
    mergeData: Ember.computed('deviceId', function () {
      let deviceId = this.deviceId;

      let _getScreenData = getScreenData(),
          screenCategory = _getScreenData.screenCategory,
          width = _getScreenData.width,
          height = _getScreenData.height,
          ratio = _getScreenData.ratio,
          biggerDimension = _getScreenData.biggerDimension,
          smallerDimenstion = _getScreenData.smallerDimenstion;
      /* eslint-disable camelcase */


      return {
        current_user: {
          device_id: deviceId
        },
        device: {
          locale: window.navigator.language,
          screen: {
            width,
            height,
            bigger_dimension: biggerDimension,
            smaller_dimension: smallerDimenstion,
            is_landscape: width > height,
            category: screenCategory,
            ratio: `${ratio[0]}:${ratio[1]}`
          }
        },
        general: {
          page_url: window.location.href,
          referrer: document.referrer
        }
      };
      /* eslint-enable camelcase */
    }),
    deviceId: Ember.computed.alias('userSettings.deviceId')
  });

  _exports.default = _default;
});