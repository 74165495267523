define("cropster-sharing-frontend/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/WrXeo5f",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Create QR code\"],null]],null],false],[0,\"\\n\\n\"],[1,[21,\"header-bar\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n  \"],[7,\"h1\"],[11,\"class\",\"no-print\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Generate sample labels\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"create-form\",null,[[\"data\",\"add\"],[[23,[\"transformedData\"]],[27,\"action\",[[22,0,[]],\"add\"],null]]]],false],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/create/template.hbs"
    }
  });

  _exports.default = _default;
});