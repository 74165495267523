define("cropster-sharing-frontend/services/keen-fetch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * Primitive method for performing ajax POST requests.
     *
     * @method makeRequest
     * @param {String} url The URL to send POST to.
     * @param {Object} [data={}] Custom request data.
     * @param {String} apiKey The API key to use for authentication
     * @param {Object} [extraOptions={}] Custom request options.
     * @returns {Ember.RSVP.Promise}
     * @private
     */
    makeRequest(url, data) {
      // we are using google tag manager instead of keen
      // transform array of events to GTM data layers:
      // { 'event-name': [{...}, {...}]}, ...}
      if (Array.isArray(window.dataLayer)) {
        Object.keys(data).forEach(event => {
          if (Array.isArray(data[event])) {
            data[event].forEach(json => {
              window.dataLayer.push(Ember.assign(json, {
                event
              }));
            });
          }
        });
      } // the quickest way to disable keen is to mitigate
      // doing the actual request to the Keen API as this
      // is the primitive method all the methods are using


      return Ember.RSVP.resolve();
    }

  });

  _exports.default = _default;
});