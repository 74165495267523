define("cropster-sharing-frontend/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cxtDEneK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\"],[9],[1,[23,[\"model\",\"title\"]],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/templates/head.hbs"
    }
  });

  _exports.default = _default;
});