define("cropster-sharing-frontend/components/import-header-bar/component", ["exports", "cropster-sharing-frontend/components/header-bar/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const basePodClassName = _podNames.default['header-bar'];

  var _default = _component.default.extend({
    // To inherit the styles of the regular header-bar...
    classNames: [basePodClassName]
  });

  _exports.default = _default;
});