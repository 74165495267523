define("cropster-sharing-frontend/components/network-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4jgTNj9G",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"notLoading\"]],[23,[\"isOfflineCapable\"]]],null]],null,{\"statements\":[[4,\"status-message\",null,[[\"icon\",\"id\"],[\"offline-capable\",\"offline-capable\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"Tip: You can use the scanner offline!\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"notLoading\"]]],null,{\"statements\":[[4,\"status-message\",null,[[\"icon\",\"id\"],[\"offline-capable\",\"not-offline-capable\"]],{\"statements\":[[4,\"if\",[[27,\"is-ios\",null,null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"To use the scanner offline, you need to update to <strong>iOS 11.3</strong> or higher.\"],null],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"To use the scanner offline, you need to update to the latest version of Chrome or Firefox.\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/network-status/template.hbs"
    }
  });

  _exports.default = _default;
});