define("cropster-sharing-frontend/import/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    keen: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    l10n: Ember.inject.service(),
    dialog: Ember.inject.service(),
    toast: Ember.inject.service(),
    queryParams: ['q', 'scan', 'import', {
      selectedLotIds: 'lots'
    }],
    q: 'OPEN',
    scan: false,
    import: false,
    selectedLotIds: Ember.computed(function () {
      return [];
    }),
    _selectedLots: Ember.computed('selectedLotIds.[]', function () {
      let store = this.store;
      return this.selectedLotIds.map(id => store.peekRecord('lot', id) || store.findRecord('lot', id));
    }),
    selectedLots: Ember.computed('_selectedLots.@each.{id,isLoaded}', 'model.@each.id', function () {
      let lots = this._selectedLots.compact().filter(lot => Ember.get(lot, 'id'));

      let allowedLotIds = this.model.mapBy('id');
      return lots.filter(lot => allowedLotIds.includes(Ember.get(lot, 'id')));
    }),
    showImport: Ember.computed('scan', 'import', 'importLots.length', function () {
      return this.import && !this.scan && this.importLots.length;
    }),
    forceImportLots: null,
    importLots: Ember.computed('selectedLots.[]', 'forceImportLots.[]', function () {
      return this.forceImportLots || this.selectedLots;
    }),
    isEmpty: Ember.computed('model.length', function () {
      return !Ember.get(this, 'model.length');
    }),
    actions: {
      toggleScan(scan) {
        Ember.set(this, 'scan', scan);
      },

      deleteLot(lot) {
        return this._deleteLotTask.perform(lot);
      },

      add(d) {
        let keen = this.keen,
            dataManager = this.dataManager,
            toast = this.toast,
            l10n = this.l10n;
        keen.sendEvent('scan-qr-code', {
          data: d
        });
        return dataManager.generateLot(d).then(lot => {
          toast.success(l10n.t('{{lotName}} was successfully added.', {
            lotName: lot.name
          }));
          this.send('reload');
        }).catch(error => {
          toast.error(error, l10n.t('An error occurred when trying to parse the code.'));
        });
      },

      openImport(lots) {
        Ember.set(this, 'import', true);
        Ember.set(this, 'scan', false);

        if (lots) {
          lots = convertToArray(lots);
          Ember.set(this, 'forceImportLots', lots);
        }
      },

      closeImport() {
        let reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        let forceImportLots = this.forceImportLots;
        Ember.set(this, 'import', false);
        Ember.set(this, 'forceImportLots', null);

        if (!forceImportLots) {
          Ember.set(this, 'selectedLotIds', []);
        }

        if (reload) {
          this.send('reload');
        }
      },

      toggleRecord(lots, isSelected) {
        let keen = this.keen;
        lots = convertToArray(lots);
        let lotIds = lots.mapBy('id');
        /* eslint-disable camelcase */

        keen.sendEvent('toggle-lot', {
          lot_count: lotIds.length,
          is_selected: isSelected
        });
        /* eslint-enable camelcase */

        let selectedLotIds = this.selectedLotIds;

        if (isSelected) {
          selectedLotIds.addObjects(lotIds);
        } else {
          selectedLotIds.removeObjects(lotIds);
        }
      }

    },
    _deleteLotTask: (0, _emberConcurrency.task)(function* (lot) {
      let l10n = this.l10n,
          dialog = this.dialog,
          keen = this.keen;

      try {
        yield dialog.confirmWarn(l10n.t('Are you sure you want to remove this coffee from your list?'), l10n.t('Remove coffee "{{coffeeName}}"', {
          coffeeName: lot.name
        }));
      } catch (e) {
        return;
      }

      keen.sendEvent('delete-lot');
      yield lot.destroyRecord();
      this.send('reload');
    })
  });

  _exports.default = _default;

  function convertToArray(item) {
    if (item.toArray) {
      return item.toArray();
    }

    if (Ember.typeOf(item) === 'array') {
      return item;
    }

    return [item];
  }
});