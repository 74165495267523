define("cropster-sharing-frontend/helpers/is-ios", ["exports", "cropster-sharing-frontend/utils/is-ios"], function (_exports, _isIos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(() => (0, _isIos.isIos)());

  _exports.default = _default;
});