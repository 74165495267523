define("cropster-sharing-frontend/components/service-worker-update-notify/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    networkStatus: Ember.inject.service(),
    hasUpdate: Ember.computed.alias('networkStatus.hasServiceWorkerUpdate')
  });

  _exports.default = _default;
});