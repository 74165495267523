define("cropster-sharing-frontend/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dataManager: Ember.inject.service(),
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    queryParams: ['data'],
    data: Ember.computed(function () {
      return [];
    }),
    transformedData: Ember.computed('data', function () {
      return this.data.map(datum => {
        if (datum.varieties) {
          datum.variety = datum.varieties;
          delete datum.varieties;
        }

        return datum;
      });
    }),
    actions: {
      add(data) {
        return this._add(data);
      }

    },

    async _add(data) {
      let dataManager = this.dataManager,
          l10n = this.l10n,
          toast = this.toast;

      try {
        let lots = await Ember.RSVP.Promise.all(data.map(d => dataManager.generateLot(d)));
        toast.success(l10n.n('You have successfully added {{count}} coffee to your import queue.', 'You have successfully added {{count}} coffees to your import queue.', lots.length));
      } catch (error) {
        toast.error(l10n.t('An error occurred when trying to add the coffees'), error);
      }
    }

  });

  _exports.default = _default;
});