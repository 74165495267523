define("cropster-sharing-frontend/components/qr-code/component", ["exports", "cropster-sharing-frontend/utils/data-formatter", "cropster-sharing-frontend/helpers/format-sample-type"], function (_exports, _dataFormatter, _formatSampleType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global qrcodegen */
  const _qrcodegen = qrcodegen,
        QrCode = _qrcodegen.QrCode; // This comes from vendor

  var _default = Ember.Component.extend({
    dataContent: null,
    showTitle: true,
    data: Ember.computed('dataContent', function () {
      let obj = this.dataContent;
      let lotStr = (0, _dataFormatter.generateCode)(obj);
      return `${window.location.origin}/add?qr=1&d=${lotStr}`;
    }),
    title: Ember.computed('dataContent', function () {
      let lotName = Ember.get(this, 'dataContent.name') || '';
      let sampleType = Ember.get(this, 'dataContent.sampleType') || '';

      if (sampleType) {
        return `${lotName} (${(0, _formatSampleType.formatSampleType)([sampleType])})`;
      }

      return lotName;
    }),
    hostName: Ember.computed(function () {
      return window.location.host;
    }),

    didInsertElement() {
      this._super(...arguments);

      this._renderQrCode();
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this._renderQrCode();
    },

    _renderQrCode() {
      let data = this.data,
          element = this.element;

      if (!element || !data) {
        return;
      }

      let qrCode = QrCode.encodeText(data, QrCode.Ecc.LOW);
      qrCode.drawCanvas(10, 0, element.querySelector('canvas'));
      Ember.set(this, 'qrCode', qrCode);
    }

  });

  _exports.default = _default;
});