define("cropster-sharing-frontend/helpers/country-name", ["exports", "ember-country-flags/helpers/country-name"], function (_exports, _countryName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _countryName.default;
    }
  });
  Object.defineProperty(_exports, "countryName", {
    enumerable: true,
    get: function get() {
      return _countryName.countryName;
    }
  });
});