define("cropster-sharing-frontend/create-samples/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onCreate(lots) {
        this.transitionToRoute('create', {
          queryParams: {
            data: lots
          }
        });
      }

    }
  });

  _exports.default = _default;
});