define("cropster-sharing-frontend/components/qr-code-reader/scanner/component", ["exports", "jsqr", "ember-concurrency", "cropster-sharing-frontend/utils/is-ios"], function (_exports, _jsqr, _emberConcurrency, _isIos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // wait for x frames
  function requestAnimationFrameDelayed(func) {
    let delayCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

    if (!delayCount) {
      return func();
    }

    requestAnimationFrame(() => requestAnimationFrameDelayed(func, delayCount - 1));
  }

  var _default = Ember.Component.extend({
    classNameBindings: ['parseCodeTask.isRunning:is-scanning', 'cameraError:has-error', 'hasVideo:has-video'],
    l10n: Ember.inject.service(),
    qrCodeScanner: Ember.inject.service(),
    action: null,
    cameraError: null,
    hasVideo: false,
    _canvasElement: null,
    isLoading: Ember.computed('cameraError', 'hasVideo', function () {
      return !this.hasVideo && !this.cameraError;
    }),
    _isTesting: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.factoryFor('config:environment').class.environment === 'test';
    }),
    cameraErrorMessage: Ember.computed('cameraError', function () {
      let cameraError = this.cameraError,
          l10n = this.l10n;

      if (!cameraError) {
        return null;
      }

      if (cameraError.toString().includes('NotAllowedError')) {
        return l10n.t('You must allow camera access for QR code scanning to work.');
      }

      return cameraError;
    }),
    // How many frames to wait to scan again
    // Lower means scanning more often
    _scanInterval: Ember.computed(function () {
      // iOS generally stutters when scanning
      // For iOS, we scan less often to reduce the stutter
      // However, this leads to degraded scanning performance on e.g. Desktop devices, so we do a bit of UA sniffing
      return (0, _isIos.isIos)() ? 10 : 3;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.startScanTask.perform();
    },

    willDestroyElement() {
      this._stopScan();

      this._super(...arguments);
    },

    // Start scanning from the video stream
    startScanTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'cameraError', null);
      let video = this.element.querySelector('.video');
      let canvas = this._canvasElement || document.createElement('canvas');
      Ember.set(this, '_canvasElement', canvas);
      (false && !(!this._isTesting || window.navigator.mediaDevices._isMock) && Ember.assert('navigator.mediaDevices.getUserMedia is mocked in tests', !this._isTesting || window.navigator.mediaDevices._isMock));

      try {
        let stream = yield window.navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment'
          }
        });
        video.srcObject = stream;
        video.setAttribute('playsinline', true); // required to tell iOS Safari we don't want fullscreen
        // Chrome throws an error if you try to play a video without user interaction first
        // This means the test fails in CI

        if (!this._isTesting) {
          video.play();
        }

        requestAnimationFrame(() => this._scan());
      } catch (error) {
        Ember.set(this, 'cameraError', error);

        if (!this._isTesting) {
          console.error(error); // eslint-disable-line
        }
      }
    }),

    // Check the video input for a QR code
    async _scan() {
      if (!this.element) {
        return;
      } // If this is false, no image was drawn to canvas yet
      // In that case, try again later


      if (!this._drawVideoImg()) {
        requestAnimationFrameDelayed(() => this._scan(), this._scanInterval);
        return;
      }

      Ember.set(this, 'hasVideo', true);
      let canvasElement = this._canvasElement;
      let canvas = canvasElement.getContext('2d');
      let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
      let code = await this._parseQrCodeFromImage(imageData);

      if (code) {
        requestAnimationFrame(() => this._parseCode(code));
      }

      requestAnimationFrameDelayed(() => this._scan(), this._scanInterval);
    },

    _barcodeDetector: Ember.computed(function () {
      return window.BarcodeDetector ? new window.BarcodeDetector() : null;
    }),

    async _parseQrCodeFromImage(_ref) {
      let data = _ref.data,
          width = _ref.width,
          height = _ref.height;
      let code = (0, _jsqr.default)(data, width, height);
      return code ? code.data : null;
    },

    // Draw the current image from the video to a canvas
    _drawVideoImg() {
      let element = this.element;

      if (!element) {
        return;
      }

      let video = element.querySelector('video');
      let canvasElement = this._canvasElement;
      let canvas = canvasElement.getContext('2d');

      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        return true;
      }
    },

    parseCodeTask: (0, _emberConcurrency.task)(function* (data) {
      this.action(data);
      yield (0, _emberConcurrency.timeout)(500);
    }).restartable(),

    _parseCode(code) {
      let data = this.qrCodeScanner.parseCode(code);

      if (data) {
        this._stopScan();

        this.parseCodeTask.perform(data);
      }
    },

    _stopScan() {
      let element = this.element;

      if (!element) {
        return;
      }

      let video = element.querySelector('video');
      let stream = video && video.srcObject;

      if (!stream) {
        return;
      }

      stream.getTracks().forEach(track => track.stop());
      video.srcObject = null;
    }

  });

  _exports.default = _default;
});