define("cropster-sharing-frontend/components/create-form/settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Attributes
    data: null,
    count: 1,
    sortedFields: null,
    qrCodeFields: null,
    labelFields: null,
    fieldsExcludedForQrCode: null,
    // Actions
    updateCount: null,
    updateShowLabels: null,
    updateQrCodeFields: null,
    updateLabelFields: null,
    updateFieldOrder: null,
    // Properties
    showMode: 'FIELDS',
    actions: {
      updateMode(mode) {
        Ember.set(this, 'showMode', mode);
      }

    }
  });

  _exports.default = _default;
});