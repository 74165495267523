define("cropster-sharing-frontend/components/create-form/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvtFZYBN",
    "block": "{\"symbols\":[\"fields\",\"fields\",\"field\"],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"displayOptions\",\"showLabels\"]],[23,[\"logoImgSrc\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"logo-container\"],[12,\"style\",[21,\"logoContainerStyle\"]],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[21,\"logoImgSrc\"]],[12,\"alt\",[27,\"t\",[\"Logo\"],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"displayOptions\",\"showLabels\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"label-container\"],[12,\"style\",[21,\"labelContainerStyle\"]],[9],[0,\"\\n\"],[4,\"parse-data-content\",null,[[\"dataContent\",\"includeName\"],[[23,[\"labelContent\"]],true]],{\"statements\":[[4,\"sort-data-content\",null,[[\"fields\",\"sortedFields\"],[[22,1,[]],[23,[\"sortedFields\"]]]],{\"statements\":[[4,\"each\",[[22,2,[]]],null,{\"statements\":[[4,\"if\",[[22,3,[\"value\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"label-field\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"displayOptions\",\"showTextLabels\"]]],null,{\"statements\":[[0,\"                \"],[7,\"span\"],[11,\"class\",\"label-field__title\"],[9],[0,\"\\n                  \"],[1,[22,3,[\"title\"]],false],[0,\":\\n                \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"              \"],[7,\"span\"],[11,\"class\",\"label-field__value\"],[9],[0,\"\\n                \"],[1,[22,3,[\"value\"]],false],[0,\"\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"qr-code-container\"],[12,\"style\",[21,\"qrCodeContainerStyle\"]],[9],[0,\"\\n  \"],[1,[27,\"qr-code\",null,[[\"dataContent\"],[[23,[\"qrCodeContent\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/create-form/item/template.hbs"
    }
  });

  _exports.default = _default;
});