define("cropster-sharing-frontend/application/route", ["exports", "cropster-sharing-frontend/utils/is-ios"], function (_exports, _isIos) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    indexedDb: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    l10n: Ember.inject.service(),
    raven: Ember.inject.service(),
    keen: Ember.inject.service(),

    beforeModel() {
      if (!('mediaDevices' in window.navigator) || !window.navigator.mediaDevices.getUserMedia) {
        let errorMessage = (0, _isIos.isIos)() ? this.l10n.t('This app requires iOS 11 to work. Due to an issue with iOS, it currently also does not work when added to the homescreen.') : this.l10n.t('Your browser does not support QR code scanning. Please use a different browser, like Chrome, Safari (11.3 or up) or Firefox.');
        return Ember.RSVP.Promise.reject(errorMessage);
      }

      this.raven.setupRavenContext();
      this.keen.trackAllPageViews();
      return this.indexedDb.setup();
    },

    afterModel() {
      this.keen.sendEvent('app-load');
      return this.dataManager.cleanUpTask.perform();
    },

    actions: {
      error(error) {
        this.intermediateTransitionTo('application_error', {
          message: error
        });
      }

    }
  });

  _exports.default = _default;
});