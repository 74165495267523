define("cropster-sharing-frontend/components/create-form/settings/fields/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Attributes
    data: null,
    sortedFields: null,
    qrCodeFields: null,
    labelFields: null,
    fieldsExcludedForQrCode: null,
    // Actions
    updateCount: null,
    updateShowLabels: null,
    updateQrCodeFields: null,
    updateLabelFields: null,
    updateFieldOrder: null,
    // Properties
    allSelectableFields: null,

    didReceiveAttrs() {
      this._super(...arguments);

      let fields = this.sortedFields.slice().removeObject('weight');
      Ember.set(this, 'allSelectableFields', fields);
    },

    actions: {
      sortEndAction() {
        let newFields = this.allSelectableFields;
        this.updateFieldOrder(newFields);
      },

      updateLabelField(field, isSelected) {
        let fields = this.labelFields.slice();

        if (isSelected) {
          fields.addObject(field);
        } else {
          fields.removeObject(field);
        }

        this.updateLabelFields(fields);
      },

      updateQrCodeField(field, isSelected) {
        let fields = this.qrCodeFields.slice();

        if (isSelected) {
          fields.addObject(field);
        } else {
          fields.removeObject(field);
        }

        this.updateQrCodeFields(fields);
      }

    }
  });

  _exports.default = _default;
});