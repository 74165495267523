define("cropster-sharing-frontend/import/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    userSettings: Ember.inject.service(),
    indexedDb: Ember.inject.service(),
    dialog: Ember.inject.service(),
    l10n: Ember.inject.service(),
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      resetApp() {
        return this._resetApp();
      }

    },

    async _resetApp() {
      let l10n = this.l10n,
          dialog = this.dialog,
          userSettings = this.userSettings,
          indexedDb = this.indexedDb,
          toast = this.toast,
          store = this.store;

      try {
        await dialog.confirmWarn(l10n.t("Are you sure you want to reset the app? This will remove unsync'd data and your local settings on the device."), l10n.t('Reset your app?'));
      } catch (e) {
        // dialog cancelled
        return;
      }

      userSettings.clear();
      await indexedDb.clear('lot');
      store.unloadAll('lot');
      toast.success(l10n.t('Your app was successfully reset.'));
    }

  });

  _exports.default = _default;
});