define("cropster-sharing-frontend/services/raven", ["exports", "ember-cli-sentry/services/raven"], function (_exports, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raven.default.extend({
    userSettings: Ember.inject.service(),
    _config: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.factoryFor('config:environment').class;
    }),

    setupRavenContext() {
      this.callRaven('setTagsContext', {
        version: Ember.get(this, '_config.APP.version')
      });
      this.callRaven('setUserContext', {
        id: Ember.get(this, 'userSettings.deviceId')
      });
    }

  });

  _exports.default = _default;
});