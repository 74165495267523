define("cropster-sharing-frontend/components/create-form/logo-img/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    dataManager: Ember.inject.service(),
    // This is a full data URI that can be used for <img src=XXX>
    imgSrc: Ember.computed.alias('dataManager.logoImgSrc'),
    actions: {
      clearLogo() {
        this.dataManager.updateLogoImgSrc(null);
      },

      selectFile(event) {
        let target = event.target;

        let _target$files = _slicedToArray(target.files, 1),
            file = _target$files[0];

        return this._loadFile(file).then(src => {
          return this.dataManager.updateLogoImgSrc(src);
        });
      }

    },

    _loadFile(file) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!FileReader || !file) {
          return reject('No file reader support or invalid file');
        }

        if (!file.type.match(/image.*/)) {
          return reject('Not an image');
        }

        let fr = new FileReader();

        fr.onload = () => {
          let src = fr.result;
          resolve(src);
        };

        fr.onerror = reject;
        fr.readAsDataURL(file);
      });
    }

  });

  _exports.default = _default;
});