define("cropster-sharing-frontend/services/network-status", ["exports", "ember-service-worker-update-notify/utils/service-worker-has-update", "ember-concurrency"], function (_exports, _serviceWorkerHasUpdate, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    userSettings: Ember.inject.service(),

    /**
     * If the user is currently online.
     *
     * @property isOnline
     * @type {Boolean}
     * @readOnly
     * @public
     */
    isOnline: Ember.computed(function () {
      return window.navigator.onLine;
    }),

    /**
     * If the app can be used while offline.
     *
     * @property isOfflineCapable
     * @type {Boolean}
     * @readOnly
     * @public
     */
    isOfflineCapable: Ember.computed.alias('hasServiceWorker'),

    /**
     * If a service worker update exists.
     * If true, the user needs to reload the app to get the updates.
     *
     * @property hasServiceWorkerUpdate
     * @type {Boolean}
     * @public
     */
    hasServiceWorkerUpdate: false,

    /**
     * If a service worker is registered.
     *
     * @property hasServiceWorker
     * @type {Boolean}
     * @readOnly
     * @public
     */
    hasServiceWorker: Ember.computed(function () {
      return !!this.userSettings.getItem('sw-was-ever-loaded');
    }),

    /**
     * If a service worker is loading.
     * This is true if the browser supports SW, but none is loaded yet.
     *
     * @property isServiceWorkerLoading
     * @type {Boolean}
     * @readOnly
     * @public
     */
    isServiceWorkerLoading: Ember.computed('_supportsServiceWorker', 'hasServiceWorker', function () {
      return this._supportsServiceWorker && !this.hasServiceWorker;
    }),
    // Used to simplify addEventListener/removeEventListener
    _onlineHandler: Ember.computed(function () {
      return () => {
        (false && !(!this.isDestroyed) && Ember.assert('handler should not be called after being destroyed', !this.isDestroyed));
        this.notifyPropertyChange('isOnline');
      };
    }),
    // Used to not register handlers in test env
    _isTesting: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.factoryFor('config:environment').class.environment === 'test';
    }),
    // If the browser supports service worker
    _supportsServiceWorker: Ember.computed(function () {
      return !!window.navigator.serviceWorker;
    }),

    init() {
      this._super(...arguments);

      this._addEventListeners();
      /* istanbul ignore next */


      if (!this._isTesting && this._supportsServiceWorker) {
        this._checkUpdate();

        this.checkLaterTask.perform();
      }
    },

    willDestroy() {
      this._super(...arguments);

      this._removeEventListeners();
    },

    _addEventListeners() {
      window.addEventListener('online', this._onlineHandler);
      window.addEventListener('offline', this._onlineHandler);
    },

    _removeEventListeners() {
      window.removeEventListener('online', this._onlineHandler);
      window.removeEventListener('offline', this._onlineHandler);
    },

    /**
     * Check if a SW update exists.
     * This will ensure the hasServiceWorker & hasServiceWorkerUpdate properties are set correctly.
     *
     * @method checkUpdate
     * @return {Promise<void>}
     * @private
     */
    async _checkUpdate() {
      this.notifyPropertyChange('hasServiceWorker');
      let hasUpdate = await (0, _serviceWorkerHasUpdate.default)();

      this._serviceWorkerSetup(hasUpdate);
    },

    _serviceWorkerSetup(hasUpdate) {
      let wasRegistered = this.userSettings.getItem('sw-was-ever-loaded');

      if (wasRegistered) {
        Ember.set(this, 'hasServiceWorkerUpdate', hasUpdate);
      } else {
        this.userSettings.setItem('sw-was-ever-loaded', true);
        this.notifyPropertyChange('hasServiceWorker');
      }
    },

    /**
     * Check every 2 mins, in the background, if a new SW update exists.
     *
     * @method checkLaterTask
     * @private
     */

    /* istanbul ignore next */
    checkLaterTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(120000); // check every 2 mins.

      if (window.navigator.serviceWorker && window.navigator.serviceWorker.getRegistration) {
        let registration = yield window.navigator.serviceWorker.getRegistration();

        if (registration) {
          registration.update();
        }

        this.checkLaterTask.perform();
      }
    }).keepLatest()
  });

  _exports.default = _default;
});