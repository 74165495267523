define("cropster-sharing-frontend/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PBcc7+Gi",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Cropster SLIC\"],null]],null],false],[0,\"\\n\"],[1,[21,\"head-layout\"],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[1,[21,\"modal-dialog\"],false],[0,\"\\n\"],[1,[21,\"toast-container\"],false],[0,\"\\n\\n\"],[1,[21,\"service-worker-update-notify\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/application/template.hbs"
    }
  });

  _exports.default = _default;
});