define("cropster-sharing-frontend/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J/aJFxCh",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[1,[21,\"header-bar\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper wrapper--small margin-top margin-bottom\"],[9],[0,\"\\n\"],[4,\"info-message\",null,[[\"type\",\"icon\"],[\"error\",\"error\"]],{\"statements\":[[4,\"if\",[[23,[\"model\",\"errors\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"model\",\"errors\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[1,[22,1,[\"detail\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"message\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,[\"model\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"model\"],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/error/template.hbs"
    }
  });

  _exports.default = _default;
});