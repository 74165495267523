define("cropster-sharing-frontend/components/lots-selected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+gtn7j88",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"display-inline-block valign-middle margin-right-xsmall\"],[9],[0,\"\\n  \"],[1,[27,\"n\",[\"{{count}} Coffee selected\",\"{{count}} Coffees selected\",[23,[\"lots\",\"length\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"c-button\",null,[[\"class\",\"action\"],[\"button button--primary valign-middle\",[27,\"action\",[[22,0,[]],[23,[\"import\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Import\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/lots-selected/template.hbs"
    }
  });

  _exports.default = _default;
});