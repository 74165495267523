define("cropster-sharing-frontend/services/asset-map", ["exports", "ember-cli-ifa/services/asset-map"], function (_exports, _assetMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _assetMap.default.extend({
    resolve() {
      let url = this._super(...arguments); // We want to enfore a leading slash


      if (!url.startsWith('http') && !url.startsWith('/')) {
        return `/${url}`;
      }

      return url;
    }

  });

  _exports.default = _default;
});