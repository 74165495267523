define("cropster-sharing-frontend/import/index/route", ["exports", "ember-cropster-common/utils/serialize-array"], function (_exports, _serializeArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    queryParams: {
      q: {
        refreshModel: true
      }
    },

    model(params) {
      let q = params.q;
      let query = {
        status: 'OPEN'
      };

      if (q === 'IMPORTED') {
        query.status = 'IMPORTED';
      }

      if (q === 'HAS_NOTE') {
        query.hasNote = true;
      }

      return this.store.query('lot', query);
    },

    setupController(controller, model) {
      this._super(...arguments);

      if (controller.q === 'OPEN' && !model.length) {
        Ember.set(controller, 'scan', true);
      } // Ensure this is always false, e.g. when coming here through the history


      Ember.set(controller, 'import', false);
    },

    resetController(controller) {
      let isExiting = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (isExiting) {
        Ember.set(controller, 'selectedLotIds', []);
        Ember.set(controller, 'import', false);
      }
    },

    deserializeQueryParam(value, urlKey) {
      if (urlKey === 'lots') {
        return (0, _serializeArray.deserializeArray)(value);
      }

      return this._super(...arguments);
    },

    serializeQueryParam(value, urlKey) {
      if (urlKey === 'lots') {
        return (0, _serializeArray.serializeArray)(value);
      }

      return this._super(...arguments);
    },

    actions: {
      reload() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});