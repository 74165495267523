define("cropster-sharing-frontend/components/status-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSettings: Ember.inject.service(),
    icon: null,
    text: null,
    messageId: null,
    _fullMessageId: Ember.computed('messageId', function () {
      return `hide-status-message-${this.messageId}`;
    }),
    show: Ember.computed(function () {
      return !this.userSettings.getItem(this._fullMessageId);
    }),
    actions: {
      markMessageAsRead() {
        let msg = this._fullMessageId;
        this.userSettings.setItem(msg, true);
        this.notifyPropertyChange('show');
      }

    }
  });

  _exports.default = _default;
});