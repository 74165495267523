define("cropster-sharing-frontend/components/lots-list/title/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['lots.length:has-selection'],
    lots: null,
    allLots: null,
    toggleRecord: null,
    isAllSelected: Ember.computed('lots.@each.id', 'allLots.@each.id', function () {
      let lots = this.lots,
          allLots = this.allLots;
      let allLotIds = allLots.mapBy('id');
      let lotIds = lots.mapBy('id');
      return allLotIds.length && !allLotIds.find(id => !lotIds.includes(id));
    })
  });

  _exports.default = _default;
});