define("cropster-sharing-frontend/utils/generate-csv", ["exports", "cropster-sharing-frontend/helpers/format-weight", "cropster-sharing-frontend/helpers/format-percentage", "cropster-sharing-frontend/helpers/format-sample-type", "cropster-sharing-frontend/utils/data-formatter"], function (_exports, _formatWeight, _formatPercentage, _formatSampleType, _dataFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateCsvFile = generateCsvFile;
  _exports.generateCsvData = generateCsvData;
  _exports.generateCsvFileContent = generateCsvFileContent;
  _exports.default = void 0;

  function generateCsvFile(lots) {
    let data = lots.map(lot => lot.getImportDataContent());
    let csvData = generateCsvData(data);
    return generateCsvFileContent(csvData);
  }

  var _default = generateCsvFile;
  _exports.default = _default;

  function parseField(field, fieldName) {
    if (!field) {
      return field;
    }

    if (Ember.typeOf(field) === 'array') {
      return field.join(', ');
    }

    if (fieldName === 'moisture') {
      return (0, _formatPercentage.formatPercentage)([field]);
    }

    if (fieldName === 'weightUnit' && Ember.typeOf(field) === 'object') {
      return (0, _formatWeight.formatWeightUnit)(field);
    }

    if (fieldName === 'sampleType') {
      return (0, _formatSampleType.formatSampleType)([field]);
    }

    return field;
  }

  function escapeField(field) {
    let columnDelimiter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ',';

    if (Ember.typeOf(field) === 'string' && (field.includes(columnDelimiter) || field.includes('"'))) {
      return `"${field.replace(/"/g, '""')}"`;
    }

    return field;
  }

  function generateCsvData(data) {
    let _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$columnDelimiter = _ref.columnDelimiter,
        columnDelimiter = _ref$columnDelimiter === void 0 ? ',' : _ref$columnDelimiter,
        _ref$lineDelimiter = _ref.lineDelimiter,
        lineDelimiter = _ref$lineDelimiter === void 0 ? '\n' : _ref$lineDelimiter;

    let keys = _dataFormatter.allFields;
    let lineItems = [keys.map(prettifyTitle)];
    lineItems.pushObjects(data.map(item => {
      return keys.map(k => {
        let value = item[k];
        return escapeField(parseField(value, k), columnDelimiter);
      });
    }));
    let rows = lineItems.map(cols => {
      return cols.join(columnDelimiter);
    });
    return rows.join(lineDelimiter);
  }

  function generateCsvFileContent(csvData) {
    let blob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8;'
    });
    return URL.createObjectURL(blob);
  }

  function prettifyTitle(title) {
    return Ember.String.capitalize(Ember.String.underscore(title).replace(/_/g, ' ')).replace(/ no$/, ' number').replace('Ico ', 'ICO ');
  }
});