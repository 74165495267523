define("cropster-sharing-frontend/components/lots-list/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    keen: Ember.inject.service(),
    lot: null,
    selectedItems: null,
    delete: null,
    import: null,
    toggleRecord: null,
    lotData: Ember.computed.alias('lot.dataContent'),
    showNote: false,
    isSelected: Ember.computed('lot.id', 'selectedItems.@each.id', function () {
      let lotId = this.lot.id;
      let selectedItems = this.selectedItems || [];
      return selectedItems.mapBy('id').includes(lotId);
    }),
    actions: {
      hideNote() {
        Ember.set(this, 'showNote', false);
      }

    },
    updateNote: (0, _emberConcurrency.task)(function* (note) {
      let lot = this.lot;
      Ember.set(lot, 'note', note);
      yield (0, _emberConcurrency.timeout)(200);
      yield lot.save();
    }).restartable(),
    addNote: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'showNote', true);
      yield (0, _emberConcurrency.timeout)(1);
      this.element.querySelector('textarea').focus();
      /* eslint-disable camelcase */

      this.keen.sendEvent('add-note', {
        lot_id: this.lot.id
      });
      /* eslint-enable camelcase */
    })
  });

  _exports.default = _default;
});