define("cropster-sharing-frontend/helpers/svg-icon", ["exports", "ember-cropster-common/helpers/svg-icon"], function (_exports, _svgIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _svgIcon.default;
    }
  });
  Object.defineProperty(_exports, "svgIcon", {
    enumerable: true,
    get: function get() {
      return _svgIcon.svgIcon;
    }
  });
});