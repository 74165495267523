define("cropster-sharing-frontend/components/lots-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ibrGeKbm",
    "block": "{\"symbols\":[\"lot\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"visibleLots\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[0,\"    \"],[1,[27,\"lots-list/item\",null,[[\"lot\",\"selectedItems\",\"toggleRecord\",\"import\",\"delete\"],[[22,1,[]],[23,[\"selectedItems\"]],[23,[\"toggleRecord\"]],[23,[\"import\"]],[23,[\"delete\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"canShowMore\"]]],null,{\"statements\":[[4,\"scroll-in-view\",null,[[\"action\"],[[27,\"action\",[[22,0,[]],\"showMore\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"button button--light\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Show more\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"showMore\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/lots-list/template.hbs"
    }
  });

  _exports.default = _default;
});