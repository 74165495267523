define("cropster-sharing-frontend/components/create-samples-form/item/component", ["exports", "ember-country-flags/fixtures/countries", "cropster-sharing-frontend/fixtures/units", "cropster-sharing-frontend/utils/weight-units"], function (_exports, _countries, _units, _weightUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Attributes
    lot: null,
    form: null,
    rowPosition: null,
    allSelectedFields: null,
    // Properties
    allCountries: Ember.computed(function () {
      return _countries.default;
    }),
    selectedCountry: Ember.computed('lot.country', 'allCountries.@each.code', function () {
      return this.allCountries.findBy('code', this.lot.country) || null;
    }),
    allProcessings: Ember.computed(function () {
      return ['NATURAL', 'SEMI_WASHED', 'FULLY_WASHED', 'PULPED_NATURAL', 'HONEY', 'MISC', 'OTHER'];
    }),
    allSampleTypes: Ember.computed(function () {
      return ['TYPE_SAMPLE', 'OFFER', 'SPOT_OFFER', 'PRE_SHIPMENT', 'ARRIVAL', 'EVALUATION'];
    }),
    allWeightUnits: Ember.computed(function () {
      return _units.weightUnits.map(_weightUnits.convertWeightUnitString);
    }),
    allCertificates: Ember.computed(function () {
      return ['Fairtrade (FLO)', 'Rainforest Alliance', 'UTZ', 'Organic Other', 'Organic JAP (JAS)', 'Organic US (NOP)', 'Organic EU', 'Bird Friendly', '4C Sustainable Coffee Growing', 'Global GAP', 'Fairtrade (FLO) + Organic', 'Cup of Excellence', 'NTC 5400', 'Resolución 4174 de 2009 Certificación BPA - ICA', 'Q Certified', 'Simbolo de Pequeños Productores', 'Organic BR (IBD)'];
    }),
    itemLabel: Ember.computed('rowPosition', function () {
      return `#${this.rowPosition + 1}`;
    }),
    actions: {
      updateCountry(countryObject) {
        Ember.set(this.lot, 'country', countryObject ? countryObject.code : undefined);
      },

      onSubmit() {// We don't actually need this, but c-form expects this to be set
      }

    }
  });

  _exports.default = _default;
});