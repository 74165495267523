define("cropster-sharing-frontend/components/qr-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EhGM1in3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"canvas\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showTitle\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"qr-code__title\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"cropster-color\"],[[\"class\"],[\"icon--small valign-middle margin-right-xsmall\"]]],false],[0,\"\\n    \"],[1,[21,\"hostName\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"qr-code__subtitle\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"data\",\"length\"]],350],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"no-print color-warning text--smaller\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Too much data - this can make scanning difficult\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/qr-code/template.hbs"
    }
  });

  _exports.default = _default;
});