define("cropster-sharing-frontend/components/status-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YnY/fShe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"status-message \",[21,\"messageClass\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"svg-jar\",[[23,[\"icon\"]]],[[\"class\"],[\"status-message__icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"status-message__text\"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"button\"],[11,\"class\",\"status-message__close button--plain no-border\"],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--grey\"]]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"markMessageAsRead\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/status-message/template.hbs"
    }
  });

  _exports.default = _default;
});