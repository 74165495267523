define("cropster-sharing-frontend/helpers/parse-field-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseFieldName = parseFieldName;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function parseFieldName(fieldName, l10n) {
    switch (fieldName) {
      case 'name':
        return l10n.t('Name');

      case 'referenceNo':
        return l10n.t('Reference #');

      case 'sampleType':
        return l10n.t('Sample type');

      case 'country':
        return l10n.t('Country');

      case 'region':
        return l10n.t('Region');

      case 'icoNo':
        return l10n.t('ICO #');

      case 'containerNo':
        return l10n.t('Container #');

      case 'location':
        return l10n.t('Location');

      case 'grade':
        return l10n.t('Grade');

      case 'variety':
        return l10n.t('Varieties');

      case 'processing':
        return l10n.t('Processing');

      case 'cropYear':
        return l10n.t('Crop year');

      case 'certificates':
        return l10n.t('Certificates');

      case 'farm':
        return l10n.t('Farm');

      case 'mill':
        return l10n.t('Mill');

      case 'importer':
        return l10n.t('Importer');

      case 'exporter':
        return l10n.t('Exporter');

      case 'weight':
        return l10n.t('Amount');

      case 'weightAmount':
        return l10n.t('Weight amount');

      case 'weightUnit':
        return l10n.t('Weight unit');

      case 'arrivalDate':
        return l10n.t('Est. arrival date');

      case 'purchaseNo':
        return l10n.t('Purchase #');

      case 'salesNo':
        return l10n.t('Sales #');

      case 'notes':
        return l10n.t('Notes');

      case 'id':
        return l10n.t('ID');

      case 'field':
        return l10n.t('Field');

      case 'project':
        return l10n.t('Project');

      case 'moisture':
        return l10n.t('Moisture');
    }

    return fieldName;
  }
  /**
   * A helper to generate a flag image.
   * Pass in onlyUrl=true as argument, to just generate the URL without the <img> tag.
   *
   * @class CountryFlag
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{country-flag 'de'}}
   * ```
   */


  var _default = Ember.Helper.extend({
    l10n: Ember.inject.service(),

    compute(_ref) {
      let _ref2 = _slicedToArray(_ref, 1),
          fieldName = _ref2[0];

      return parseFieldName(fieldName, this.l10n);
    }

  });

  _exports.default = _default;
});