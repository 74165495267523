define("cropster-sharing-frontend/transforms/json", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deserialize = deserialize;
  _exports.serialize = serialize;
  _exports.default = void 0;

  function deserialize(json) {
    if (!json || typeof json !== 'string') {
      return null;
    }

    return JSON.parse(json);
  }

  function serialize(json) {
    if (!json) {
      return null;
    }

    return JSON.stringify(json);
  }

  var _default = _transform.default.extend({
    deserialize,
    serialize
  });

  _exports.default = _default;
});