define("cropster-sharing-frontend/components/create-form/settings/display/component", ["exports", "ember-cropster-common/utils/merge-deep"], function (_exports, _mergeDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    data: null,
    displayOptions: null,
    options: null,
    updateDisplayOptions: null,
    availableSizeUnits: Ember.computed(function () {
      return ['mm', 'cm', 'in'];
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      let options = (0, _mergeDeep.default)(this.displayOptions);
      Ember.set(this, 'options', options);
    },

    _sendUpdateAction() {
      this.updateDisplayOptions(this.options);
    },

    actions: {
      updateShowLabels(val) {
        let options = this.options;
        Ember.set(options, 'showLabels', val);

        this._sendUpdateAction();
      },

      updateWidth(val) {
        let options = this.options;
        Ember.set(options, 'width', val || null);

        this._sendUpdateAction();
      },

      updateHeight(val) {
        let options = this.options;
        Ember.set(options, 'height', val || null);

        this._sendUpdateAction();
      },

      updateSizeUnit(val) {
        let options = this.options;
        Ember.set(options, 'sizeUnit', val);

        this._sendUpdateAction();
      },

      resetSize() {
        let options = this.options;
        Ember.set(options, 'width', null);
        Ember.set(options, 'height', null);

        this._sendUpdateAction();
      },

      updateFlow(val) {
        let options = this.options;
        Ember.set(options, 'flow', val); // Also set defaults depending on flow

        if (val === 'VERTICAL') {
          Ember.set(options, 'textAlignment', 'center');
          Ember.set(options, 'labelColumns', 1);
          Ember.set(options, 'showTextLabels', false);
        } else {
          Ember.set(options, 'textAlignment', 'left');
          Ember.set(options, 'labelColumns', 2);
          Ember.set(options, 'showTextLabels', true);
        }

        this._sendUpdateAction();
      },

      updateTextAlignment(val) {
        let options = this.options;
        Ember.set(options, 'textAlignment', val);

        this._sendUpdateAction();
      },

      updateShowTextLabels(val) {
        let options = this.options;
        Ember.set(options, 'showTextLabels', val);

        this._sendUpdateAction();
      },

      updateLabelColumns(val) {
        let options = this.options;
        Ember.set(options, 'labelColumns', val || 1);

        this._sendUpdateAction();
      },

      updateLogoWidth(val) {
        let options = this.options;
        Ember.set(options, 'logoWidth', val || null);

        this._sendUpdateAction();
      },

      updateQrCodeWidth(val) {
        let options = this.options;
        Ember.set(options, 'qrCodeWidth', val || null);

        this._sendUpdateAction();
      }

    }
  });

  _exports.default = _default;
});