define("cropster-sharing-frontend/utils/weight-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertWeightUnitString = convertWeightUnitString;
  const weightPrefixes = ['BAG', 'BOX', 'BARREL'];

  function removeUndefinedFromObject(obj) {
    Object.keys(obj).forEach(field => Ember.isNone(obj[field]) ? delete obj[field] : null);
    return obj;
  }

  function convertWeightUnitString(unitStr) {
    if (!unitStr) {
      return null;
    }

    let type = weightPrefixes.find(prefix => unitStr.indexOf(prefix) === 0);
    let size = unitStr.replace(/[A-Z]*/g, '').replace(/_/g, '.') * 1 || undefined;
    let unit = type ? unitStr.substr(type.length) : unitStr;
    unit = unit.replace(/([^A-Z_]|(_\d))*/g, '');
    return removeUndefinedFromObject({
      size,
      unit,
      type
    });
  }
});