define("cropster-sharing-frontend/components/import-header-bar/main-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['show:is-open'],
    show: false,

    click() {
      this.toggleProperty('show');
    }

  });

  _exports.default = _default;
});