define("cropster-sharing-frontend/components/qr-code-reader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    qrCodeScanner: Ember.inject.service(),
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    showScanner: false,
    action: null,
    toggleScan: null,
    scannedCoffeeCount: Ember.computed.alias('qrCodeScanner.scannedCoffeeCount'),
    _isTesting: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.factoryFor('config:environment').class.environment === 'test';
    }),

    _parseStr(data) {
      if (data.startsWith('http') && data.includes('d=')) {
        return data.substr(data.indexOf('d=') + 2);
      }

      return data;
    },

    actions: {
      show() {
        this.toggleScan(true);
      },

      scan(data) {
        let toast = this.toast,
            l10n = this.l10n;

        try {
          let d = this._parseStr(data);

          if (d) {
            this.action(d);
          }
        } catch (e) {
          toast.error(e.toString(), l10n.t('Error scanning the QR code'), {
            timeOut: 0
          });
          console.error(e); // eslint-disable-line
        }
      },

      // The scanning is very hard to simulate in tests, so we provide this as an easy to use alternative
      mockScan(event) {
        if (this._isTesting) {
          this.send('scan', event.target.value);
        }
      }

    },
    hideTask: (0, _emberConcurrency.task)(function* () {
      this.qrCodeScanner.resetScannedCoffeeCount();
      yield (0, _emberConcurrency.timeout)(100);
      this.toggleScan(false);
      yield (0, _emberConcurrency.timeout)(1);
    })
  });

  _exports.default = _default;
});