define("cropster-sharing-frontend/components/create-form/logo-img/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kzQxo0bB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"imgSrc\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[21,\"imgSrc\"]],[12,\"alt\",[27,\"t\",[\"Logo\"],null]],[9],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"button--plain link--secondary\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Change logo\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"clearLogo\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\"],[11,\"accept\",\"image/*\"],[12,\"id\",[28,[[21,\"elementId\"],\"-logo-file\"]]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"selectFile\"],null]],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[12,\"for\",[28,[[21,\"elementId\"],\"-logo-file\"]]],[11,\"class\",\"button button--icon button--secondary file-input-label\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"upload\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Upload logo\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/create-form/logo-img/template.hbs"
    }
  });

  _exports.default = _default;
});