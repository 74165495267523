define("cropster-sharing-frontend/application/adapter", ["exports", "ember-indexeddb/adapters/indexed-db"], function (_exports, _indexedDb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _indexedDb.default.extend();

  _exports.default = _default;
});