define("cropster-sharing-frontend/utils/is-ios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isIos = isIos;
  _exports.default = void 0;

  function isIos() {
    let platform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.platform;
    return !!platform && /iPad|iPhone|iPod/.test(platform);
  }

  var _default = isIos;
  _exports.default = _default;
});