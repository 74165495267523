define("cropster-sharing-frontend/services/moment", ["exports", "ember-moment/services/moment", "cropster-sharing-frontend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _Ember = Ember,
        get = _Ember.get;

  var _default = _moment.default.extend({
    defaultFormat: get(_environment.default, 'moment.outputFormat')
  });

  _exports.default = _default;
});