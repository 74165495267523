define("cropster-sharing-frontend/components/service-worker-update-notify/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T4Ot8AWU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"hasUpdate\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"service-worker-update-notify\"],[11,\"href\",\"\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"A new version is available, click here to update.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/service-worker-update-notify/template.hbs"
    }
  });

  _exports.default = _default;
});