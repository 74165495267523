define("cropster-sharing-frontend/components/lots-selected/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    lots: null,
    import: null
  });

  _exports.default = _default;
});