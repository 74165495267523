define("cropster-sharing-frontend/components/import-form/component", ["exports", "ember-concurrency", "ember-cropster-common/utils/promise-queue", "moment", "cropster-sharing-frontend/utils/generate-csv", "ember-cropster-common/utils/dom/trigger-click"], function (_exports, _emberConcurrency, _promiseQueue, _moment, _generateCsv, _triggerClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['text-center'],
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    networkStatus: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    cookies: Ember.inject.service(),
    keen: Ember.inject.service(),
    // Attributes
    lots: null,
    // Actions
    close: null,
    // Properties
    rememberSetting: false,
    // Set on didInsertElement
    isOnline: Ember.computed.alias('networkStatus.isOnline'),
    csvLink: Ember.computed('lots.@each.dataContent', function () {
      return (0, _generateCsv.generateCsvFile)(this.lots);
    }),
    // If the user has logged in to c-sar in the last 4 hours
    // This is not bullet proof, but should be good enough for most cases
    isCsarLoggedIn: Ember.computed(function () {
      let cookies = this.cookies;
      let lastCsarLogin = cookies.read('csar-logged-in');

      if (lastCsarLogin) {
        let date = (0, _moment.default)(lastCsarLogin * 1);
        return (0, _moment.default)().diff(date, 'hours') <= 4;
      }

      return false;
    }),
    _csarBaseUrl: 'https://c-sar.cropster.com/apps/',
    _isTesting: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.factoryFor('config:environment').class.environment === 'test';
    }),

    didInsertElement() {
      this._super(...arguments);

      this._checkAutoImport();

      if (!this._isTesting && !this.isCsarLoggedIn) {
        this.checkCsarLoggedIn.perform();
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      this._cleanupCsv();
    },

    actions: {
      generateCsv() {
        this.generateCsv.perform();
        return !this._isTesting; // When testing, stop bubbling (to not download the file)
      },

      updateRememberSetting(shouldRemember) {
        Ember.set(this, 'rememberSetting', shouldRemember);
      }

    },

    _close() {
      let importMethod = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let close = this.close;

      if (this.rememberSetting) {
        this.dataManager.updateSelectedImportMethod(importMethod);
      }

      close(true);
    },

    // Remove the CSV URL object from memory
    // Every object URL is unique, and they otherwise stay in memory until the browser window is closed
    // This way, we save some memory
    _cleanupCsv() {
      let objectUrl = this.csvLink;

      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    },

    _checkAutoImport() {
      let importMethod = this.dataManager.selectedImportMethod;

      if (importMethod) {
        Ember.set(this, 'rememberSetting', true);
      }
      /* eslint-disable camelcase */


      this.keen.sendEvent('import-open', {
        auto_method: importMethod
      });
      /* eslint-enable camelcase */

      switch (importMethod) {
        case 'CSAR':
          this.importCsar.perform();
          break;

        case 'CSV':
          (0, _triggerClick.default)(this.element.querySelector('[data-import-csv]'));
          break;
      }
    },

    _markImported(lots) {
      let now = (0, _moment.default)();
      return (0, _promiseQueue.default)(lots, lot => {
        Ember.set(lot, 'importDate', now);
        Ember.set(lot, 'status', 'IMPORTED');
        return lot.save();
      });
    },

    _buildUrl() {
      let lots = this.lots;
      let host = this._csarBaseUrl;
      let baseUrl = `${host}lots/samples/create/many/import?data`;
      let data = lots.map(lot => lot.prepareDataContent());
      return `${baseUrl}=${encodeURIComponent(JSON.stringify(data))}`;
    },

    _openUrl(url) {
      window.location.href = url;
    },

    importCsar: (0, _emberConcurrency.task)(function* () {
      let lots = this.lots; // Mark all lots correctly

      yield this._markImported(lots);
      yield this._trackImport(lots, 'CSAR'); // Open URL

      let url = this._buildUrl();

      this._openUrl(url);

      this._close('CSAR');
    }),
    generateCsv: (0, _emberConcurrency.task)(function* () {
      let lots = this.lots; // Mark all lots correctly

      yield this._markImported(lots);
      yield this._trackImport(lots, 'CSV');

      this._close('CSV');
    }),
    // We check every 5 seconds if the user has logged in in the meanwhile
    checkCsarLoggedIn: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(5000);
      this.notifyPropertyChange('isCsarLoggedIn');

      if (!this.isCsarLoggedIn) {
        this.checkCsarLoggedIn.perform();
      }
    }).keepLatest(),

    async _trackImport(lots, method) {
      let keen = this.keen;
      /* eslint-disable camelcase */

      try {
        await keen.sendEventImmediately('import', {
          method,
          is_csar_logged_in: this.isCsarLoggedIn,
          lot_count: lots.length
        });
      } catch (e) {} // we want to continue even if this fails, e.g. if an ad blocker is used

      /* eslint-enable camelcase */

    }

  });

  _exports.default = _default;
});