define("cropster-sharing-frontend/components/network-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    networkStatus: Ember.inject.service(),
    isOfflineCapable: Ember.computed.reads('networkStatus.isOfflineCapable'),
    notLoading: Ember.computed.not('networkStatus.isServiceWorkerLoading')
  });

  _exports.default = _default;
});