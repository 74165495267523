define("cropster-sharing-frontend/helpers/format-weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatWeight = formatWeight;
  _exports.formatWeightUnit = formatWeightUnit;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatWeight(_ref) {
    let _ref2 = _slicedToArray(_ref, 2),
        weightAmount = _ref2[0],
        weightUnit = _ref2[1];

    if (Ember.isNone(weightAmount) && !weightUnit) {
      return '';
    }

    weightUnit = weightUnit || {};
    let unitStr = formatWeightUnit(weightUnit);

    if (unitStr && !Ember.isNone(weightAmount)) {
      unitStr = `x ${unitStr}`;
    }

    weightAmount = Ember.isNone(weightAmount) ? '' : weightAmount;
    return `${weightAmount} ${unitStr}`.trim();
  }

  function formatWeightUnit() {
    let weightUnit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let _weightUnit$type = weightUnit.type,
        type = _weightUnit$type === void 0 ? '' : _weightUnit$type,
        _weightUnit$unit = weightUnit.unit,
        unit = _weightUnit$unit === void 0 ? '' : _weightUnit$unit,
        _weightUnit$size = weightUnit.size,
        size = _weightUnit$size === void 0 ? '' : _weightUnit$size;
    type = type.toLowerCase();
    unit = unit.toLowerCase();
    return `${size}${unit} ${type}`.trim();
  }

  var _default = Ember.Helper.helper(formatWeight);

  _exports.default = _default;
});