define("cropster-sharing-frontend/components/lots-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    lots: null,
    selectedItems: null,
    toggleRecord: null,
    import: null,
    delete: null,
    limit: 15,
    maxLimit: Ember.computed.alias('sortedLots.length'),
    canShowMore: Ember.computed('limit', 'maxLimit', function () {
      return this.limit < this.maxLimit;
    }),
    sortedLots: Ember.computed('lots.@each.createdDate', function () {
      let lots = this.lots || [];
      return lots.toArray().sort((a, b) => Ember.compare(b.createdDate.valueOf(), a.createdDate.valueOf()));
    }),
    visibleLots: Ember.computed('sortedLots.[]', 'limit', function () {
      let limit = this.limit,
          sortedLots = this.sortedLots;
      return sortedLots.slice(0, limit);
    }),
    actions: {
      showMore() {
        let limit = this.limit,
            maxLimit = this.maxLimit;
        limit = Math.min(limit + 15, maxLimit);
        Ember.set(this, 'limit', limit);
      }

    }
  });

  _exports.default = _default;
});