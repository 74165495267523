define("cropster-sharing-frontend/helpers/get-value", ["exports", "ember-cropster-common/helpers/get-value"], function (_exports, _getValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getValue.default;
    }
  });
});