define("cropster-sharing-frontend/router", ["exports", "cropster-sharing-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('add');
    this.route('create');
    this.route('import', function () {
      this.route('show', {
        path: '/lot/:lot_id'
      });
      this.route('help');
      this.route('settings');
    });
    this.route('create-samples');
  });
  var _default = Router;
  _exports.default = _default;
});