define("cropster-sharing-frontend/components/create-form/component", ["exports", "cropster-sharing-frontend/utils/data-formatter"], function (_exports, _dataFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSettings: Ember.inject.service(),
    keen: Ember.inject.service(),
    // Attributes
    data: null,
    // Properties
    count: 1,
    sortedFields: null,
    qrCodeFields: null,
    labelFields: null,
    fieldsExcludedForQrCode: Ember.computed(function () {
      return ['notes', 'id'];
    }),
    displayOptions: Ember.computed(function () {
      return {
        showLabels: true,
        width: null,
        height: null,
        sizeUnit: 'mm',
        flow: 'HORIZONTAL',
        textAlignment: 'left',
        showTextLabels: true,
        labelColumns: 2,
        logoWidth: null,
        qrCodeWidth: null
      };
    }),
    // In order to be able to use {{#each}}
    countProp: Ember.computed('count', function () {
      let count = this.count;
      return new Array(count);
    }),
    isCompact: Ember.computed('displayOptions.showLabels', 'count', function () {
      return !this.displayOptions.showLabels && this.count === 1;
    }),
    hasAdded: false,

    didReceiveAttrs() {
      this._super(...arguments);

      Ember.set(this, 'hasAdded', false);
      Ember.set(this, 'defaultDisplayOptions', Object.assign({}, this.displayOptions));

      this._initFields();
    },

    actions: {
      updateDisplayOptions(options) {
        Ember.set(this, 'displayOptions', options);
        this.userSettings.setItem('label-display-options', options);
        this.keen.sendEvent('create-update-display-options', {
          options
        });
      },

      updateCount(count) {
        Ember.set(this, 'count', count);
        this.keen.sendEvent('create-update-count', {
          count
        });
      },

      updateQrCodeFields(fields) {
        Ember.set(this, 'qrCodeFields', fields);
        this.userSettings.setItem('selected-qr-code-fields', fields);
        this.keen.sendEvent('create-update-qr-code-fields', {
          fields
        });
      },

      updateLabelFields(fields) {
        Ember.set(this, 'labelFields', fields);
        this.userSettings.setItem('selected-label-fields', fields);
        this.keen.sendEvent('create-update-label-fields', {
          fields
        });
      },

      updateFieldOrder(newFieldOrder) {
        // Ensure weight field is at pos of weightAmount
        newFieldOrder = this._fixFieldsOrder(newFieldOrder);
        Ember.set(this, 'sortedFields', newFieldOrder);
        this.userSettings.setItem('label-field-order', newFieldOrder);
        this.keen.sendEvent('create-update-field-order', {
          fields: newFieldOrder
        });
      },

      print() {
        /* eslint-disable camelcase */
        this.keen.sendEvent('create-print', {
          label_fields: this.labelFields,
          qr_code_fields: this.qrCodeFields,
          sorted_fields: this.sortedFields,
          count: this.count,
          display_options: this.displayOptions
        });
        /* eslint-enable camelcase */

        window.print();
      },

      add() {
        Ember.set(this, 'hasAdded', true);
        return this.add(...arguments);
      }

    },

    _initFields() {
      let data = this.data;
      let allPossibleFields = this.userSettings.getItem('label-field-order') || [];
      allPossibleFields.addObjects(_dataFormatter.allFields);
      let usedFields = allPossibleFields.filter(field => {
        return data.find(fields => !Ember.isNone(fields[field]));
      });
      let qrCodeFields = this.userSettings.getItem('selected-qr-code-fields') || usedFields.slice().removeObjects(this.fieldsExcludedForQrCode);
      let labelFields = this.userSettings.getItem('selected-label-fields') || usedFields.slice();
      usedFields = this._fixFieldsOrder(usedFields);
      Ember.set(this, 'sortedFields', usedFields);
      Ember.set(this, 'qrCodeFields', qrCodeFields);
      Ember.set(this, 'labelFields', labelFields);
      let options = this.userSettings.getItem('label-display-options');

      if (options) {
        Ember.set(this, 'displayOptions', Object.assign({}, this.defaultDisplayOptions, options));
      }
    },

    // Ensure weight field is at pos of weightAmount
    _fixFieldsOrder(fields) {
      if (!fields.includes('weightUnit') || !fields.includes('weightAmount')) {
        return fields;
      } // First, we want to figure out if the weightUnit field was dragged
      // As we need to know which field we should move (and which should remain where it is)


      let currentFieldOrderWithoutAmount = this.sortedFields ? this.sortedFields.slice() : [];
      currentFieldOrderWithoutAmount.removeObject('weightAmount');
      let newFieldOrderWithoutAmount = fields.slice().removeObject('weightAmount');
      let unitPosHasChanged = currentFieldOrderWithoutAmount.indexOf('weightUnit') !== newFieldOrderWithoutAmount.indexOf('weightUnit'); // Now we determine which field is the anchor (e.g. remains) and which should move
      // If the weightAmount field is the anchor, we want to move the other field below it (so the offset is 1)
      // Else, we leave the offset at 0, which will place the other (amount) field above the anchor (unit)

      let anchorField = unitPosHasChanged ? 'weightUnit' : 'weightAmount';
      let moveField = anchorField === 'weightUnit' ? 'weightAmount' : 'weightUnit';
      let fieldOffset = anchorField === 'weightAmount' ? 1 : 0;
      fields.removeObject(moveField);
      let anchorFieldPos = fields.indexOf(anchorField);
      fields.insertAt(anchorFieldPos + fieldOffset, moveField);
      return fields;
    }

  });

  _exports.default = _default;
});