define("cropster-sharing-frontend/components/header-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vTH0/inl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"index\"],[[\"class\"],[\"logo no-border\"]],{\"statements\":[[0,\"  \"],[1,[27,\"svg-jar\",[\"cropster-interchange\"],[[\"class\"],[\"block icon--large\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/header-bar/template.hbs"
    }
  });

  _exports.default = _default;
});