define("cropster-sharing-frontend/services/indexed-db-configuration", ["exports", "ember-indexeddb/services/indexed-db-configuration"], function (_exports, _indexedDbConfiguration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _indexedDbConfiguration.default.extend({
    currentVersion: 1,
    mapTable: Ember.computed(function () {
      return {
        lot: item => {
          return {
            id: this._toString(Ember.get(item, 'id')),
            json: this._cleanObject(item),
            status: Ember.get(item, 'attributes.status') || 'OPEN',
            hasNote: Ember.get(item, 'attributes.note') ? 1 : 0,
            createdDate: Ember.get(item, 'attributes.createdDate'),
            importedDate: Ember.get(item, 'attributes.importedDate')
          };
        }
      };
    }),
    version1: Ember.computed(function () {
      return {
        stores: {
          lot: '&id,*status,*hasNote,*createdDate,*importedDate'
        }
      };
    })
  });

  _exports.default = _default;
});