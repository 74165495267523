define("cropster-sharing-frontend/components/create-samples-form/component", ["exports", "cropster-sharing-frontend/utils/data-formatter", "ember-cropster-common/utils/merge-deep"], function (_exports, _dataFormatter, _mergeDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSettings: Ember.inject.service(),
    lots: null,
    showFieldSelector: false,
    allFields: Ember.computed(function () {
      return _dataFormatter.allFields.slice();
    }),
    // The selected fields - we take them from the user settings (if set)
    // Else, we use the provided default list
    allSelectedFields: Ember.computed(function () {
      return this.userSettings.getItem('create-samples-selected-fields') || ['name', 'sampleType', 'country', 'variety', 'processing', 'icoNo', 'salesNo', 'farm', 'weightAmount', 'weightUnit'];
    }),
    // To make sure the fields are always in the same order
    sortedSelectedFields: Ember.computed('allSelectedFields.[]', 'allFields.[]', function () {
      let allSelectedFields = this.allSelectedFields,
          allFields = this.allFields;
      return allFields.filter(fieldName => allSelectedFields.includes(fieldName));
    }),

    init() {
      this._super(...arguments);

      this._initLots();
    },

    _initLots() {
      Ember.set(this, 'lots', []);

      this._addLot(); // We build the computed property here, so we can access all fields and correctly watch for changes on all of them


      Ember.defineProperty(this, 'filledLots', Ember.computed(`lots.@each.{${_dataFormatter.allFields.join(',')}}`, function () {
        return this.lots.filter(lot => {
          return Object.keys(lot).filter(property => {
            let val = lot[property];
            return !Ember.isEmpty(val);
          }).length > 2;
        });
      }));
    },

    actions: {
      toggleSelectedField(field, isSelected) {
        let allSelectedFields = this.allSelectedFields;

        if (isSelected) {
          allSelectedFields.addObject(field);
        } else {
          allSelectedFields.removeObject(field);
        }

        this.userSettings.setItem('create-samples-selected-fields', allSelectedFields);
      },

      addLot() {
        this._addLot();
      },

      onSubmit() {
        // Only take non-empty lots
        // Empty lots will be ignored for creation
        // Also make sure to make a clone of each instance, to not accidentally mutate the original lots
        let lots = this.filledLots.map(lot => (0, _mergeDeep.default)(lot)); // Parse fields

        lots.forEach(lot => {
          let arrivalDate = lot.arrivalDate,
              moisture = lot.moisture;

          if (arrivalDate) {
            Ember.set(lot, 'arrivalDate', arrivalDate.format('YYYY-MM-DD'));
          }

          if (moisture) {
            Ember.set(lot, 'moisture', {
              amount: moisture,
              unit: 'PERCENT'
            });
          }

          if (!lot.weightAmount) {
            delete lot.weightUnit;
          }
        });

        if (lots.length === 0) {
          return;
        }

        this.onCreate(lots);
      },

      toggleShowFieldSelector() {
        this.toggleProperty('showFieldSelector');
      }

    },

    _addLot() {
      this.lots.pushObject({
        version: 1,
        weightUnit: {
          unit: 'KG'
        }
      });
    }

  });

  _exports.default = _default;
});