define("cropster-sharing-frontend/components/qr-code-reader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uecUQZ/v",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"scannedCoffeeCount\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"scanned-coffee-count\"],[9],[0,\"\\n    \"],[1,[27,\"n\",[\"{{count}} Coffee successfully scanned!\",\"{{count}} Coffees successfully scanned!\",[23,[\"scannedCoffeeCount\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showScanner\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"camera-container \",[27,\"if\",[[23,[\"hideTask\",\"isRunning\"]],\"camera-container--closing\"],null]]]],[9],[0,\"\\n\\n    \"],[7,\"button\"],[11,\"class\",\"camera-close\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"hideTask\"]]],null]],null]],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--inherit-color valign-middle icon--small\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"qr-code-reader/scanner\",null,[[\"action\",\"class\"],[[27,\"action\",[[22,0,[]],\"scan\"],null],\"qr-code-scanner\"]]],false],[0,\"\\n    \"],[7,\"input\"],[11,\"class\",\"hidden\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"mockScan\"],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"camera-button\"],[9],[0,\"\\n    \"],[1,[27,\"svg-jar\",[\"qr-code\"],[[\"class\"],[\"icon--inherit-color valign-middle\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Scan\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"show\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/qr-code-reader/template.hbs"
    }
  });

  _exports.default = _default;
});