define("cropster-sharing-frontend/add/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataManager: Ember.inject.service(),
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    keen: Ember.inject.service(),

    model(params) {
      let keen = this.keen;
      let d = params.d,
          qr = params.qr;

      if (qr) {
        keen.sendEvent('scan-qr-code', {
          data: encodeURIComponent(d)
        });
      }

      return this.dataManager.generateLot(d);
    },

    afterModel(model) {
      let l10n = this.l10n,
          toast = this.toast;
      toast.success(l10n.t('{{lotName}} was successfully added.', {
        lotName: model.name
      }));
      let params = this.paramsFor(this.routeName);

      if (params.qr) {
        return this.transitionTo('index', {
          queryParams: {
            scan: true
          }
        });
      }

      this.transitionTo('index');
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(controller, 'd', null);
        Ember.set(controller, 'qr', 0);
      }
    },

    actions: {
      error(error, transition) {
        let l10n = this.l10n,
            toast = this.toast;

        if (Ember.get(transition, 'to.queryParams.qr')) {
          toast.warning(l10n.t('Please scan the QR code again.'));
        } else {
          toast.error(l10n.t('Could not add lot, please try again.'));
        }

        this.transitionTo('index');
      }

    }
  });

  _exports.default = _default;
});