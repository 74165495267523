define("cropster-sharing-frontend/components/create-form/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['displayOptionIsVertical:is-vertical'],
    attributeBindings: ['displayStyle:style'],
    dataManager: Ember.inject.service(),
    logoImgSrc: Ember.computed.alias('dataManager.logoImgSrc'),
    dataContent: null,
    sortedFields: Ember.computed(function () {
      return [];
    }),
    labelFields: Ember.computed(function () {
      return [];
    }),
    qrCodeFields: Ember.computed(function () {
      return [];
    }),
    displayOptions: Ember.computed(function () {
      return {};
    }),
    // If the flow is vertical (e.g. logo on top, then labels, then qr code on bottom)
    // Default is horizontal (logo left, labels center, qr code right)
    displayOptionIsVertical: Ember.computed.equal('displayOptions.flow', 'VERTICAL'),
    // Styles for the element itself
    displayStyle: Ember.computed('displayOptions.{width,height,sizeUnit,textAlignment}', function () {
      let _this$displayOptions = this.displayOptions,
          width = _this$displayOptions.width,
          height = _this$displayOptions.height,
          sizeUnit = _this$displayOptions.sizeUnit,
          textAlignment = _this$displayOptions.textAlignment;
      let styles = [];

      if (width) {
        styles.push(`width: ${width}${sizeUnit}`);
        styles.push('overflow-x: hidden');
      }

      if (height) {
        styles.push(`height: ${height}${sizeUnit}`);
        styles.push('overflow-y: hidden');
      }

      if (textAlignment) {
        styles.push(`text-align: ${textAlignment}`);
      }

      return Ember.String.htmlSafe(styles.join('; '));
    }),
    labelContainerStyle: Ember.computed('displayOptions.labelColumns', function () {
      let labelColumns = this.displayOptions.labelColumns;

      if (labelColumns) {
        return Ember.String.htmlSafe(`column-count: ${labelColumns}`);
      }

      return Ember.String.htmlSafe('');
    }),
    logoContainerStyle: Ember.computed('displayOptions.{logoWidth,sizeUnit}', function () {
      let _this$displayOptions2 = this.displayOptions,
          logoWidth = _this$displayOptions2.logoWidth,
          sizeUnit = _this$displayOptions2.sizeUnit;

      if (logoWidth) {
        return Ember.String.htmlSafe(`width: ${logoWidth}${sizeUnit}`);
      }

      return Ember.String.htmlSafe('');
    }),
    qrCodeContainerStyle: Ember.computed('displayOptions.{qrCodeWidth,sizeUnit}', function () {
      let _this$displayOptions3 = this.displayOptions,
          qrCodeWidth = _this$displayOptions3.qrCodeWidth,
          sizeUnit = _this$displayOptions3.sizeUnit;

      if (qrCodeWidth) {
        return Ember.String.htmlSafe(`width: ${qrCodeWidth}${sizeUnit}`);
      }

      return Ember.String.htmlSafe('');
    }),
    qrCodeContent: Ember.computed('dataContent', 'qrCodeFields', function () {
      let dataContent = this.dataContent,
          qrCodeFields = this.qrCodeFields;
      return qrCodeFields.reduce((obj, field) => {
        obj[field] = dataContent[field];
        return obj;
      }, {
        version: dataContent.version
      });
    }),
    labelContent: Ember.computed('dataContent', 'labelFields', function () {
      let dataContent = this.dataContent,
          labelFields = this.labelFields;
      return labelFields.reduce((obj, field) => {
        obj[field] = dataContent[field];
        return obj;
      }, {});
    })
  });

  _exports.default = _default;
});