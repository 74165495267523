define("cropster-sharing-frontend/services/user-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service allows to set and load user settings.
   *
   * @namespace Service
   * @class UserSettings
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    /**
     * The local storage namespace where to save the settings.
     *
     * @property _localStorageNamespace
     * @type {String}
     * @default 'user-settings'
     * @protected
     */
    _localStorageNamespace: 'cropster-sharing-user-settings',
    _isTesting: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.factoryFor('config:environment').class.environment === 'test';
    }),
    deviceId: Ember.computed(function () {
      let deviceId = this.getItem('device-id');

      if (!deviceId) {
        try {
          deviceId = this._isTesting ? 'device-id-testing' : btoa(unescape(encodeURIComponent(`${window.navigator.userAgent}-${new Date()}`)));
          this.setItem('device-id', deviceId);
        } catch (error) {// ignore, no device id
        }
      }

      return deviceId;
    }),

    /**
     * The setting data, loaded from local storage & deserialized into an object.
     *
     * @property _data
     * type {Object}
     * @protected
     */
    _data: Ember.computed(function () {
      let localStorageNamespace = Ember.get(this, '_localStorageNamespace');
      let data = {};

      if (this._isTesting) {
        return data;
      }

      try {
        data = window.localStorage.getItem(localStorageNamespace);
        data = data ? JSON.parse(data) : {};
      } catch (e) {
        /* eslint-disable no-console */
        console.error('Could not load from local storage', e);
        /* eslint-enable no-console */
      }

      return data;
    }),

    init() {
      this._super(...arguments); // Force calculation


      this._data;
      this.deviceId;
    },

    /**
     * Save the data to the local storage.
     *
     * @method _saveData
     * @private
     */
    _saveData() {
      let data = Ember.get(this, '_data');
      let localStorageNamespace = Ember.get(this, '_localStorageNamespace'); // Do not save in testing env.

      if (this._isTesting) {
        return;
      }

      try {
        window.localStorage.setItem(localStorageNamespace, JSON.stringify(data));
      } catch (e) {
        /* eslint-disable no-console */
        console.error('Could not save to local storage', e);
        /* eslint-enable no-console */
      }
    },

    /**
     * Get a single item.
     *
     * @method getItem
     * @param {String} item
     * @return {Mixed}
     * @public
     */
    getItem(item) {
      let data = Ember.get(this, '_data');
      return Ember.get(data, item);
    },

    /**
     * Set a single item.
     *
     * @method setItem
     * @param {String} item
     * @param {Mixed} value
     * @public
     */
    setItem(item, value) {
      let data = Ember.get(this, '_data');
      Ember.set(data, item, value);
      Ember.run.debounce(this, this._saveData, 250);
    },

    clear() {
      let deviceId = this.deviceId;
      Ember.set(this, '_data', {
        'device-id': deviceId
      });

      this._saveData();
    }

  });

  _exports.default = _default;
});