define("cropster-sharing-frontend/components/lots-list/title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8XBqqqaY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"display-inline-block valign-middle\"],[9],[0,\"\\n  \"],[1,[27,\"c-input\",null,[[\"type\",\"checked\",\"update\",\"disabled\",\"id\"],[\"checkbox\",[23,[\"isAllSelected\"]],[27,\"action\",[[22,0,[]],[23,[\"toggleRecord\"]],[23,[\"allLots\"]]],null],[27,\"not\",[[23,[\"allLots\"]]],null],[27,\"concat\",[[23,[\"elementId\"]],\"-toggle\"],null]]]],false],[0,\"\\n\\n  \"],[7,\"label\"],[12,\"for\",[28,[[21,\"elementId\"],\"-toggle\"]]],[11,\"class\",\"valign-middle\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"display-inline-block no-margin valign-middle\"],[9],[0,\"\\n      \"],[14,1,[[23,[\"allLots\"]]]],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/lots-list/title/template.hbs"
    }
  });

  _exports.default = _default;
});