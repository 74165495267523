define("cropster-sharing-frontend/import/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7rcPm1Br",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[23,[\"model\",\"name\"]]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"margin-bottom-medium\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Back to overview\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"lot-details\",null,[[\"lot\"],[[23,[\"model\"]]]]],false],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/import/show/template.hbs"
    }
  });

  _exports.default = _default;
});