define("cropster-sharing-frontend/utils/data-formatter", ["exports", "js-binary", "buffer", "ember-cropster-common/utils/merge-deep"], function (_exports, _jsBinary, _buffer, _mergeDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateCode = generateCode;
  _exports.readCode = readCode;
  _exports.validate = validate;
  _exports.allFields = void 0;
  // It will try the schemas in order when decoding
  // If we change the schema, we should add a new one on top, pushing the old one(s) down
  // This way, old codes will continue to work
  const allSchemas = [new _jsBinary.Type({
    version: 'uint',
    'name?': 'string',
    'sampleType?': 'string',
    'referenceNo?': 'string',
    'country?': 'string',
    'region?': 'string',
    'icoNo?': 'string',
    'containerNo?': 'string',
    'location?': 'string',
    'grade?': 'string',
    'certificates?': ['string'],
    'farm?': 'string',
    'importer?': 'string',
    'exporter?': 'string',
    'mill?': 'string',
    'weightUnit?': {
      'unit?': 'string',
      'size?': 'string',
      'type?': 'string'
    },
    'weightAmount?': 'string',
    'arrivalDate?': 'string',
    'purchaseNo?': 'string',
    'salesNo?': 'string',
    'variety?': 'string',
    'processing?': 'string',
    'cropYear?': 'string',
    'field?': 'string',
    'project?': 'string',
    'moisture?': {
      'unit?': 'string',
      'amount?': 'string'
    }
  }), new _jsBinary.Type({
    version: 'uint',
    'name?': 'string',
    'sampleType?': 'string',
    'referenceNo?': 'string',
    'country?': 'string',
    'region?': 'string',
    'icoNo?': 'string',
    'containerNo?': 'string',
    'location?': 'string',
    'grade?': 'string',
    'certificates?': ['string'],
    'farm?': 'string',
    'importer?': 'string',
    'exporter?': 'string',
    'mill?': 'string',
    'weightUnit?': {
      'unit?': 'string',
      'size?': 'string',
      'type?': 'string'
    },
    'weightAmount?': 'string',
    'arrivalDate?': 'string',
    'purchaseNo?': 'string',
    'salesNo?': 'string',
    'variety?': 'string',
    'processing?': 'string',
    'cropYear?': 'string'
  })];
  const allFields = ['id', 'name', 'referenceNo', 'sampleType', 'country', 'region', 'icoNo', 'containerNo', 'location', 'grade', 'variety', 'processing', 'cropYear', 'certificates', 'farm', 'importer', 'exporter', 'mill', 'field', 'project', 'moisture', 'weightAmount', 'weightUnit', 'arrivalDate', 'purchaseNo', 'salesNo', 'notes'];
  _exports.allFields = allFields;

  let _textEncoder;

  function getTextEncoder() {
    if (_textEncoder) {
      return _textEncoder;
    }

    _textEncoder = new TextEncoder('utf-8');
    return _textEncoder;
  }

  function schemaEncode(obj) {
    let schemas = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : allSchemas;
    let error;

    for (let i = 0; i < schemas.length; i++) {
      try {
        return schemas[i].encode(obj);
      } catch (e) {
        // do nothing
        error = e;
      }
    }

    throw new Error(error);
  }

  function schemaDecode(binaryBuffer) {
    let schemas = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : allSchemas;
    let error;

    for (let i = 0; i < schemas.length; i++) {
      try {
        return schemas[i].decode(binaryBuffer);
      } catch (e) {
        // do nothing
        error = e;
      }
    }

    throw new Error(error);
  }

  function numberToString(val) {
    if (Number.isNaN(val * 1)) {
      return undefined;
    }

    let num = val * 1;
    return `${num.toFixed(2) * 1}`;
  }

  function generateCode(obj) {
    let shouldEncode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    obj = (0, _mergeDeep.default)(obj); // notes are always excluded, as they get too big

    delete obj.notes; // We include numbers as strings to avoid floating point issues

    if (obj.weightAmount) {
      obj.weightAmount = numberToString(obj.weightAmount);
    }

    if (Ember.get(obj, 'weightUnit.size')) {
      obj.weightUnit.size = numberToString(obj.weightUnit.size);
    }

    if (obj.moisture) {
      obj.moisture.amount = numberToString(obj.moisture.amount);
    } // This generated a binary Buffer


    let buffer = schemaEncode(obj); // We need to convert this to a UTF-8 string for the QR codes

    let str = buffer.toString('utf8');
    return shouldEncode ? encodeURIComponent(str) : str;
  }

  function readCode(str) {
    // This might be URL encoded
    str = decodeURIComponent(str); // First, we generate a plain Uint8Array

    let uint8Array = getTextEncoder().encode(str); // Then, we create a binary buffer from this (which js-binary requires)

    let binaryBuffer = _buffer.Buffer.from(uint8Array, 'binary'); // Finally, we decode the binary buffer to JSON


    let obj = schemaDecode(binaryBuffer); // Convert number fields to numbers

    if (obj.weightAmount) {
      obj.weightAmount = obj.weightAmount * 1;
    }

    if (Ember.get(obj, 'weightUnit.size')) {
      obj.weightUnit.size = obj.weightUnit.size * 1;
    }

    if (Ember.get(obj, 'moisture.amount')) {
      obj.moisture.amount = obj.moisture.amount * 1;
    }

    return cleanObject(obj);
  }

  function cleanObject(obj) {
    Object.keys(obj).forEach(k => {
      if (Ember.isNone(obj[k])) {
        delete obj[k];
      }
    });
    return obj;
  } // TODO


  function validate() {
    let errors = [];
    let warnings = [];
    return {
      errors,
      warnings,
      hasError: !!errors.length,
      hasWarning: !!warnings.length
    };
  }
});