define("cropster-sharing-frontend/components/import-header-bar/main-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ztBuRbc5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"nav\"],[11,\"class\",\"nav\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"button\"],[11,\"class\",\"nav__button\"],[12,\"title\",[27,\"t\",[\"Toggle menu\"],null]],[9],[10],[0,\"\\n\"],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"nav__list\"],[9],[0,\"\\n\\n\"],[4,\"link-to\",[\"import.index\",[27,\"query-params\",null,[[\"q\"],[\"OPEN\"]]]],[[\"class\"],[\"nav__item\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Scanned coffees\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"import.index\",[27,\"query-params\",null,[[\"q\"],[\"IMPORTED\"]]]],[[\"class\"],[\"nav__item\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Imported coffees\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"import.settings\"],[[\"class\"],[\"nav__item\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Settings\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"import.help\"],[[\"class\"],[\"nav__item\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"How does it work?\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"create-samples\"],[[\"class\"],[\"nav__item nav__item--with-separator\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"Create labels\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/components/import-header-bar/main-nav/template.hbs"
    }
  });

  _exports.default = _default;
});