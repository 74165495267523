define("cropster-sharing-frontend/components/sort-data-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    fields: null,
    sortedFields: null,
    sortedFieldData: Ember.computed('fields.[]', 'sortedFields.[]', function () {
      let sortedFields = this.sortedFields,
          fields = this.fields;

      if (!sortedFields) {
        return fields;
      }

      let sorted = sortedFields.map(fieldName => fields.findBy('field', fieldName)).compact();
      sorted.addObjects(fields);
      return sorted;
    })
  });

  _exports.default = _default;
});