define("cropster-sharing-frontend/services/data-manager", ["exports", "cropster-sharing-frontend/utils/data-formatter", "moment", "ember-concurrency"], function (_exports, _dataFormatter, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    l10n: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    raven: Ember.inject.service(),

    /**
     * The previously selected import method.
     * One of: CSAR, CSV.
     *
     * @property selectedImportMethod
     * @type {'CSAR'|'CSV'}
     * @public
     * @readOnly
     */
    selectedImportMethod: Ember.computed(function () {
      return this.userSettings.getItem('selected-import-method') || null;
    }),

    /**
     * A previously uploaded logo src.
     * This is a data URL.
     *
     * @property logoImgSrc
     * @type {String}
     * @public
     * @readOnly
     */
    logoImgSrc: Ember.computed(function () {
      return this.userSettings.getItem('logo-img-src') || null;
    }),

    /**
     * Update the import method.
     *
     * @method updateSelectedImportMethod
     * @param {'CSAR'|'CSV'} method
     * @public
     */
    updateSelectedImportMethod(method) {
      this.userSettings.setItem('selected-import-method', method);

      this.userSettings._saveData(); // Ensure it saves immediately


      this.notifyPropertyChange('selectedImportMethod');
    },

    /**
     * Update the logo src.
     * This expects a data URL.
     *
     * @method updateLogoImgSrc
     * @param {String} src
     * @public
     */
    updateLogoImgSrc(src) {
      this.userSettings.setItem('logo-img-src', src);
      this.notifyPropertyChange('logoImgSrc');
    },

    /**
     * Clean up all imported lots older than 30 days.
     *
     * @method cleanUpTask
     * @public
     */
    cleanUpTask: (0, _emberConcurrency.task)(function* () {
      let store = this.store;
      let lots = yield store.query('lot', {
        status: 'IMPORTED'
      });
      let today = (0, _moment.default)();
      let dateDiff = 30;
      let oldLots = lots.filter(lot => today.diff(lot.createdDate, 'days') > dateDiff);
      yield Ember.RSVP.Promise.all(oldLots.map(lot => lot.destroyRecord()));
      oldLots.forEach(lot => lot.unloadRecord());
    }),

    /**
     * Generate a lot from a given string data.
     *
     * @method generateLot
     * @param {String} d
     * @return {Model.Lot}
     * @public
     */
    generateLot(d) {
      let l10n = this.l10n,
          raven = this.raven;
      let data;

      if (typeof d === 'object') {
        data = d;
      } else {
        try {
          data = (0, _dataFormatter.readCode)(d);
        } catch (error) {
          let errorMessage = l10n.t('The code is invalid.');

          try {
            raven.captureError(error);
          } catch (e) {// This throws in dev :(
          }

          return Ember.RSVP.Promise.reject(errorMessage);
        }
      }

      let validation = (0, _dataFormatter.validate)(data);
      validation.warnings.forEach(str => console.warn(str)); // eslint-disable-line no-console

      let name = data.name || data.referenceNo || data.icoNo || data.containerNo || 'Sample';

      if (!validation.hasError) {
        let store = this.store;
        let lot = store.createRecord('lot', {
          name,
          status: 'OPEN',
          createdDate: (0, _moment.default)(),
          dataContent: data
        });
        return lot.save();
      } else {
        return Ember.RSVP.Promise.reject(validation.errors.firstObject);
      }
    }

  });

  _exports.default = _default;
});