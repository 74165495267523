define("cropster-sharing-frontend/services/qr-code-scanner", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    l10n: Ember.inject.service(),
    toast: Ember.inject.service(),
    scannedCoffeeCount: 0,
    _previousScannedData: Ember.computed(function () {
      return [];
    }),
    _previousScanDate: null,

    parseCode(data) {
      let previousData = this._previousScannedData; // To prevent continuous firing
      // If the code has already been scanned, don't scan it again

      if (previousData.includes(data)) {
        this._tryShowMultipleMessage();

        return null;
      }

      if (data) {
        previousData.pushObject(data);
        Ember.set(this, '_previousScanDate', (0, _moment.default)());
        this.incrementProperty('scannedCoffeeCount');
      }

      return data;
    },

    _tryShowMultipleMessage() {
      let toast = this.toast,
          l10n = this.l10n;
      let previousScanDate = this._previousScanDate;

      if (previousScanDate && (0, _moment.default)().diff(previousScanDate, 'seconds') >= 3) {
        toast.warning(l10n.t('You have already scanned this code'));
      }
    },

    resetScannedCoffeeCount() {
      Ember.set(this, 'scannedCoffeeCount', 0);
      Ember.set(this, '_previousScannedData', []);
      Ember.set(this, '_previousScanDate', null);
    }

  });

  _exports.default = _default;
});