define("cropster-sharing-frontend/helpers/country-flag", ["exports", "ember-country-flags/helpers/country-flag"], function (_exports, _countryFlag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _countryFlag.default;
    }
  });
});