define("cropster-sharing-frontend/utils/service-worker-has-update", ["exports", "ember-service-worker-update-notify/utils/service-worker-has-update"], function (_exports, _serviceWorkerHasUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _serviceWorkerHasUpdate.default;
    }
  });
});