define("cropster-sharing-frontend/import/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DAD+5CBW",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"page-title\",[[27,\"t\",[\"Settings\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"wrapper wrapper--small margin-bottom\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Settings\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Resetting the app will remove all your scanned coffees as well as all your settings.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"c-button\",null,[[\"class\",\"action\"],[\"button--error button--icon\",[27,\"action\",[[22,0,[]],\"resetApp\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",[\"delete\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Reset app\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[11,\"class\",\"text--secondary text--small\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Version\"],null],false],[0,\":\\n    \"],[1,[21,\"app-version\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-sharing-frontend/import/settings/template.hbs"
    }
  });

  _exports.default = _default;
});